import {DateTime} from 'luxon';

export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

export const dateRange = (from_date, to_date, locale = env.locale) => {
    function SEOstartDate(date, innerHTML) {
        return `<time itemprop="startDate" datetime="${date.toISODate()}">${innerHTML}</time>`;
    }
    function SEOendDate(date, innerHTML) {
        return `<time itemprop="endDate" datetime="${date.toISODate()}">${innerHTML}</time>`;
    }
    const _s = SEOstartDate;
    const _e = SEOendDate;
    const d1 = DateTime.fromISO(from_date).setLocale(locale);
    if ( to_date ) {
        const d2 = DateTime.fromISO(to_date).setLocale(locale);
        const same_year = d1.year === d2.year;
        const same_month = d1.month === d2.month;
        const same_date = d1.day === d2.day;
        // Long format output for dates in different years
        // EG. Jun 15, 2016 - Feb 6, 2017
        if ( !same_year ) {
            return _s(d1, d1.toLocaleString(DateTime.DATE_MED)) + " – " + _e(d2, d2.toLocaleString(DateTime.DATE_MED));
        }
        // Same year but different month
        // Medium output
        else if (same_year && !same_month) {
            if ( locale.toLowerCase() === 'fr' ) {
                // EG. 15 Juin - 6 Fev, 2017
                return _s(d1, d1.toFormat('d LLL')) + " – " + _e(d2, d2.toFormat('d LLL, y'));
            }
            // If it ain't French it must be English
            else {
                // EG. Jun 15 - Feb 6, 2017
                return _s(d1, d1.toFormat('LLL d')) + " – " + _e(d2, d2.toFormat('LLL d, y'));
            }
        }
        // hang on, that's not a range
        else if (same_year && same_month && same_date) {
            return d1.toLocaleString(DateTime.DATE_MED);
        }
        // same year and same month
        // Short output
        else {
            if ( locale.toLowerCase() === 'fr' ) {
                // 15-17 Juin, 2017
                return _s(d1, d1.toFormat('d')) + "–" + _e(d2, d2.toFormat('d LLL, y'));
            }
            // If it ain't French it must be English
            else {
                // Jun 15-17, 2017
                return _s(d1, d1.toFormat('LLL d')) + "–" + _e(d2, d2.toFormat('d, y'));
            }
        }
    }
    else {
        return _s(d1, d1.toLocaleString(DateTime.DATE_MED));
    }
}

export const dateRangeFromFormat = (from, to, format, locale = env.locale) => {
    const from_date = DateTime.fromFormat(from, format);
    const to_date = DateTime.fromFormat(to, format);
    return dateRange(from_date, to_date, locale);
}

export const timeRange = (from_date, to_date, locale = env.locale) => {
    const d1 = DateTime.fromISO(from_date).setLocale(locale);

    if ( to_date ) {
        const d2 = DateTime.fromISO(to_date).setLocale(locale);
        const same_year = d1.year === d2.year;
        const same_month = d1.month === d2.month;
        const same_date = d1.day === d2.day;
        const same_hour = d1.hour === d2.hour;
        const same_minute = d1.minute === d2.minute;

        if ( same_year && same_month && same_date ) {
            if ( same_hour && same_minute ) {
                return d1.toLocaleString(DateTime.TIME_SIMPLE);
            }
            else {
                return `${d1.toLocaleString(DateTime.TIME_SIMPLE)}–${d2.toLocaleString(DateTime.TIME_SIMPLE)}`;
            }
        }
        else {
            return `${d1.toLocaleString(DateTime.DATETIME_MED)}–${d2.toLocaleString(DateTime.DATETIME_MED)}`;
        }
    }
    else {
        return d1.toLocaleString(DateTime.DATETIME_MED);
    }
}

export const timeRangeFromFormat = (from, to, format, locale = env.locale) => {
    const from_date = DateTime.fromFormat(from, format);
    const to_date = DateTime.fromFormat(to, format);
    return timeRange(from_date, to_date, locale);
}