import MapStyles from '../data/mapstyles.json';

export default {
    init(callback = () => null, mapEl) {

        // vars

		const apiKey = env.googleMapsApiKey || false;
        let infoWindow, geocoder, map, bounds, markers = [];

        // load maps JS
        
		const loadMapsJs = () => {

			if ( !apiKey ) {
				console.warn('No Google maps API key');
				return;
			}

            // init google maps
            
			let script = document.createElement('script');
			script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&language=${env.locale}&region=CA&callback=initMap`;
			script.defer = true;
			window.initMap = () => {

                infoWindow = new google.maps.InfoWindow();
                geocoder = new google.maps.Geocoder();
                bounds = new google.maps.LatLngBounds();

                if ( !mapEl ) {
                    console.warn('No map element provided');
                    return;
                }
                
                // create map
                
                map = new google.maps.Map( mapEl, {
                    center: new google.maps.LatLng( 0, 0 ),
                    zoom: 14,
                    maxZoom: 18,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: MapStyles,
                } );

                // listen to map move event

                google.maps.event.addListenerOnce(map, 'idle', () => {
                    try {
                        callback(map);
                    }
                    catch (e) {
                        console.error(e);
                    }
                });
              
            }

			document.head.appendChild( script );
		};

        // add marker

        const addMarker = ( location, label, markerClickHandler = null ) => {

            const marker = new google.maps.Marker( {
                position: location.latLng,
                map,
                label,
                title: location.location,
            } );
    
            const onMarkerClicked = markerClickHandler || function( e ) {
                infoWindow.setPosition( this.position );
                infoWindow.setContent( this.title );
                infoWindow.setOptions( { pixelOffset: new google.maps.Size( 0, -30 ) } );
                infoWindow.open( this.map );
            }
    
            marker.addListener( 'click', onMarkerClicked.bind( marker ) );

            markers.push(marker);
            bounds.extend(location.latLng);

            if ( markers.length == 1 ) {
                map.setCenter(location.latLng);
            }
            else {
                map.fitBounds( bounds );
            }

            return marker;
        };

        // async geocoding

		const geocodeLocation = ( address, country = 'CA', attempt = 0 ) => {
			return new Promise((resolve, reject) => {
				geocoder.geocode({
					address: address,
					componentRestrictions: {
						country
					}
				}, ( results, status ) => {
                    if ( status === google.maps.GeocoderStatus.OK ) {
						resolve(results[0].geometry.location);
					}
					else {
                        // try again (up to 3 times)
                        //console.warn('failed', status, address);
                        attempt++;
                        if ( attempt < 3 ) {
                            setTimeout(async () => {
                                const location = await geocodeLocation(address, country, attempt);
                                resolve(location)
                            }, 100);    
                        }
                        else {
                            resolve(false);
                        }
					}
				});
			})
		};        

        // load the maps JS
    
        loadMapsJs();
               
        return {
            getMap: () => map,
            getInfoWindow: () => infoWindow,
            addMarker,
            geocodeLocation,
        };
    }
}