export default {
    init() {
        // JavaScript to be fired on all pages
        console.log('common [ysc]');

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
