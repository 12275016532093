import Maps from '../libs/maps';
import { asyncForEach, dateRange, timeRangeFromFormat } from '../libs/utils';

export default {
	init () {
		console.log( 'single-fair' );

		// dom elements
		
		const mapEl = $('#map')[0];

		// vars		

		const event_id = $('[data-event-id]').data('eventId');
		const portalApiBase = env.portal.apiBaseUrl || false;
		let fair = null;
		
		// no fair defined
		
		if ( event_id == 'single-fair' ) {
			location.href = $('[data-back-link]').attr('href');
			return;
		}

		// load fair
        
        let fairPromise = $.getJSON(`${portalApiBase}/regional-fair/${event_id}`);
		
		// set text from api response

		const getFairData = async () => { 
			try {
				fair = (await fairPromise)['data'];
				populateText(fair);
				
            }
            catch (err) {
				console.error("error loading fair", err);
				const metaRobots = document.createElement('meta');
				metaRobots.name = 'robots';
				metaRobots.content = 'noindex';
				document.head.appendChild(metaRobots);			
				return;
			}
		}

		// load fair content into page

		const populateText = () => {

			// content

			$('[data-fair-virtual]').hide().filter(`[data-fair-virtual="${fair.is_virtual}"]`).show();
			$('[data-fair-event-year]').html(fair.event_year || '');
			$('[data-fair-location]').html(fair.location || '');
			$('[data-fair-address]').html(fair.address || '');
			$('[data-fair-city]').html(fair.city || '');
			$('[data-fair-province]').html(fair.province.code || '');
			$('[data-fair-postal-code]').html(fair.postal_code || '');
			$('[data-fair-region-name]').html(fair.region_name || '');
			$('[data-fair-description]').html(fair.description || '');
			$('[data-fair-date-range]').html(dateRange(fair.event_start_date, fair.event_end_date));
			$('[data-fair-contact-name]').html(fair.contact_name || '');

			// show description

			$('[data-fair-has-description')
				.toggle(!!fair.description);

			// show event date

			$('[data-fair-has-event-date')
				.toggle(!!fair.event_start_date && !!fair.event_end_date);

			// show contact section

			$('[data-fair-has-contact')
				.toggle(!!fair.contact_name || !!fair.contact_email || !!fair.website);

			// email links

			$('[data-fair-contact-email]')
				.html(fair.contact_email || '')
				.attr('href', `mailto:${fair.contact_email || ''}`);

			// website link

			$('[data-fair-website]')
				.toggle(!!fair.website)
				.html((fair.website || '').replace(/(^\w+:|^)\/\//, '')) // remove protocol
				.attr('href', fair.website);

			// reg url

			const externalUrl = fair.external_registration_url ? ( fair.external_registration_url.slice(0, 4) === 'http' ? fair.external_registration_url : `http://${fair.external_registration_url}` ) : false;

			// show reg url section

			$('[data-fair-has-external-registration-url]')
				.toggle(!!externalUrl)

			$('[data-fair-external-registration-url]')
				.toggle(!!externalUrl)
				.html((externalUrl || '').replace(/(^\w+:|^)\/\//, '')) // remove protocol
				.attr('href', externalUrl);

			// show schedule section

			$('[ data-fair-has-schedule]')
				.toggle((!!fair.public_viewing.date && fair.public_viewing.date !== 'NULL') || (!!fair.awards_ceremony.date && fair.awards_ceremony.date !== 'NULL'));

			let additional_schedules = Array.isArray(fair.additional_schedules) ? fair.additional_schedules : [];

			// public viewings and awards ceremonies

			[
				{ attr: 'public_viewing', type: 'public-viewing' },
				{ attr: 'awards_ceremony', type: 'awards-ceremony' }
			]
			.forEach(({ attr, type }) => {

				if ( fair[attr] ) {
					$(`[data-fair-has-${type}]`).toggle(!!fair[attr].date);
					$(`[data-fair-${type}-date-range]`).html(dateRange(fair[attr].date, null));

					if ( !!fair[attr].date ) {
						let template = $(`[data-${type}-schedules] .fair-schedule-date-time`).detach();
						let schedules = [ { ...fair[attr] } ];
		
						additional_schedules.forEach(schedule => {
							if ( schedule.type === type ) {
								schedules.push({ ...schedule })
							}
						});
						
						schedules.forEach(schedule => {
							const newEl = template.clone();

							if ( schedule.start_time && schedule.end_time ) {
								$(newEl.find('[data-date]')).html(dateRange(schedule.date, null));
								$(newEl.find('[data-time-range]')).html(timeRangeFromFormat(schedule.start_time, schedule.end_time, 'HH:mm'));
			
								newEl.appendTo(`[data-${type}-schedules]`);	
							}
							else {
								$('<div>–</div>').appendTo(`[data-${type}-schedules]`);	
							}		
						})
					}
				}				

			})
			
			// additional addresses

			if( Array.isArray(fair.additional_addresses) && fair.additional_addresses.length > 0 ) {
				const template = $('[data-fair-additional-address]').detach();
				
				fair.additional_addresses.forEach((address, index) => {
					const newEl = template.clone();
					const markerLabel = ( index + 2 ).toString();

					$(newEl.find('[data-fair-additional-address-marker-label]')).html(markerLabel);
					$(newEl.find('[data-fair-additional-address-activity]')).html(address.activity || '');
					$(newEl.find('[data-fair-additional-address-location]')).html(address.location || '');
					$(newEl.find('[data-fair-additional-address-address]')).html(address.address || '');
					$(newEl.find('[data-fair-additional-address-city]')).html(address.city || '');
					$(newEl.find('[data-fair-additional-address-province]')).html(address.province.code || '');
					$(newEl.find('[data-fair-additional-address-postal-code]')).html(address.postal_code || '');

					newEl.appendTo('[data-fair-additional-addresses]');
				});

			}
			else {
				$('[data-fair-additional-addresses]').hide();
			}

			// additional schedules
			
			if ( Array.isArray(fair.additional_schedules) && fair.additional_schedules.length > 0 ) {
				const template = $('[data-fair-additional-schedule]').detach();

				fair.additional_schedules.forEach(schedule => {
					const newEl = template.clone();

					console.log(schedule);

					$(newEl.find('[data-fair-additional-schedule-type]')).html(schedule.type || '');
					$(newEl.find('[data-fair-additional-schedule-time-range]')).html(timeRangeFromFormat(schedule.start_time, schedule.end_time, 'HH:mm'));

					newEl.appendTo('[data-fair-additional-schedules]');
				});
			}
			else {
				$('[data-fair-additional-schedules]').hide();
			}

			// set doc title

			if ( fair.event_year) {
				document.title = `${fair.event_year} ${env.lang.regional_fair}: ${fair.region_name}`;
			}
			else {
				document.title = `${env.lang.regional_fair}: ${fair.region_name}`;
			}

			// done loading

			$('[data-event-id]').attr('data-loading', false);
		}

		// add locations to map

        const addLocations = async () => {

			if ( !fair ) {
				console.log('fair not defined yet, retry', fair);
				setTimeout(() => addLocations(), 500);
				return;
			}

			await asyncForEach([fair, ...(fair.additional_addresses || [])], async (loc, index) => {
				const markerLabel = ( index + 1 ).toString();
				let addressToSearch, latLng;			
				
                if ( loc.latLng ) {
                    loc.marker = addMarker(loc, markerLabel);
                }
                else {
					addressToSearch = `${loc.address}, ${loc.city}, ${loc.province.code} ${loc.postal_code}`;
                    latLng = await geocodeLocation(addressToSearch);
					if ( latLng ) {
						loc.latLng = latLng;
						loc.marker = addMarker( loc, markerLabel );	
					}
                }
            });
        }

		// grab fair data

		getFairData();
		
		// init the map

		const { getMap, addMarker, geocodeLocation } = Maps.init(addLocations, mapEl);
	},
	finalize () {
		
	},
};
