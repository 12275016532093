// YSC (child) theme
// -------------------------

//import jQuery from 'jQuery';
import './child.ysc.scss';

import Router from './util/Router';
import common from './routes/child.ysc/common';
import home from './routes/child.ysc/home';

import singlePost from './routes/parent/singlePost';
import projectDirectory from './routes/parent/projectDirectory';
import singleProject from './routes/parent/singleProject';
import regionalFairs from './routes/parent/regionalFairs';
import singleFair from './routes/parent/singleFair';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** News articles */
  singlePost,
  /** CWSF project directory */
  projectDirectory,
  /** CWSF project */
  'project': singleProject,
  /** Regional Fair (fka sfiab) Finder */
  regionalFairs,
  /** Single Regional Fair */
  singleFair,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
