import $ from 'jquery';
import Maps from '../libs/maps';
import { asyncForEach, dateRange } from '../libs/utils';

export default {
	init () {
		console.log('regional-fairs');

		// dom elements

		const mapEl = $('#map')[0];
		const dropdown = $('#province-dropdown');
		const eventList = $("#event-list");

		// vars

		const fourHRegion = $('[data-four-h-region-id]').data('fourHRegionId');
		const portalApiBase = env.portal.apiBaseUrl || false;
		const all = 'all';
		let mapTimeout;
		let Locations = [];

		// 4H

		try {
			$.getJSON(`${portalApiBase}/regional-fair/region/${fourHRegion}?lang=${env.locale}`)
				.then(response => response.data)
				.then(fair => {
					$('[data-four-h-fair-virtual]').hide().filter(`[data-four-h-fair-virtual="${fair.is_virtual}"]`).show();
					$('[data-four-h-fair-city]').html(fair.city);
					$('[data-four-h-fair-province]').html(fair.province.name[env.locale]);
				});
		}
		catch (e) {
			console.error(e);
		}

		// provinces
		try {
			$.getJSON(`${portalApiBase}/provinces?lang=${env.locale}`)
				.then(result => result.data)
				.then(provinces => {
					// remove international provinc
					let excludeCodes = ['IN'];

					provinces = provinces
						.filter(p => !excludeCodes.includes(p.province_code));
					
					let nonGeoCodes = ['CA'];

					// add geo provinces to dropdown

					let geoProvinces = provinces
						.filter(p => !nonGeoCodes.includes(p.province_code))
						.sort((a, b) => a.province.localeCompare(b.province));

                    geoProvinces.forEach( province => {
                        $( `[data-province]` ).append( `<option value="${province.id}">${province.province}</option>` )
                    } );

					// add non-geo provinces to dropdown

					let nonGeoProvinces = provinces
						.filter(p => nonGeoCodes.includes(p.province_code));

					nonGeoProvinces.forEach( province => {
						$( `[data-province]` ).append( `<option value="${province.id}">${province.province}</option>` )
					} );
				});
		}
		catch (e) {
			console.error(e);
		}

		// add locations
		const addLocations = async () => {
			let bounds = new google.maps.LatLngBounds();

			// loading

			$(mapEl)
				.wrap('<div style="position: relative" />')
				.before('<div class="loading-overlay" />');

			dropdown
				.find(`option:not([value="${all}"])`)
				.prop('disabled', true);

            try {
                Locations = (await $.getJSON(`${portalApiBase}/regional-fairs`))['data'];
				Locations = Locations.filter(l => !!l.region_name) // only show fairs with data
				Locations.sort((a, b) => a.region_name > b.region_name ? 1 : -1);
            }
            catch (e) {
                console.error(e);
			}

			// add locations to list

			Locations.forEach((loc, i) => {
				const markerLabel = ( i + 1 ).toString();

				// enable province in dropdown
				
				if ( loc.province.id ) {
					dropdown.find(`[value="${loc.province.id}"]`).prop('disabled', false);
				}

				// add list item

				const 
					li = $( '<li class="location-list-item" />' ),
					icon = !loc.is_virtual ? 'icons/map-pin' : 'icons/world',
					title = $(`<h2 class="location-name text--bold text--gray-dark"><span class="number text--bold text--red">${markerLabel}</span> ${loc.region_name} <svg class="icon ${icon}"><use xlink:href="#sprite-${icon}"></use></svg></h2>`),
					dates = (loc.event_start_date && loc.event_end_date) ? $(`<p class="location-dates">${dateRange(loc.event_start_date, loc.event_end_date)}</p>`): '',
					address = (loc.location && loc.address && loc.city && loc.province.code) ? $(`<address>${loc.location}<br/>${loc.address}<br/>${loc.city}, ${loc.province.code}</address>`) : '',
					link = $(`<a class="link-more-info link link--purple link-underline" href="#fair-modal" data-modal>${env.lang.more_info}</a>`);

				// populate modal when link is clicked

				link
					.on('click', e => {
						populateModal({
							...loc,
						});
					})

				$(li)
					.attr('data-id', markerLabel)
					.data('location', loc)
					.append(title)
					.append(dates)
					.append(!loc.is_virtual ? address : '')
					.append(link)
					.appendTo(eventList);

				loc.listItem = li;
			});
			
			// enable dropdown

			dropdown
				.attr('disabled', false)
				.on('change', onDropdownChanged);

			// add markers to map

			await asyncForEach(Locations, async (loc, i) => {
				const markerLabel = ( i + 1 ).toString();

				// add marker
				if ( loc.latLng ) {
					loc.marker = addMarker( loc, markerLabel, onMarkerClicked );
				}
				if ( parseFloat(loc.latitude) && parseFloat(loc.longitude) ) {
					loc.latLng = new google.maps.LatLng( parseFloat(loc.latitude), parseFloat(loc.longitude) );
					loc.marker = addMarker( loc, markerLabel, onMarkerClicked );
				}
				else if ( ! loc.is_virtual && loc.address && loc.city ) {
					const addressToSearch = `${loc.address}, ${loc.city}, ${loc.postal_code}`;
					const latlng = await geocodeLocation(addressToSearch);
					if ( latlng ) {
						loc.latLng = latlng;
						loc.marker = addMarker( loc, markerLabel, onMarkerClicked );	
					}	
				}	
			});

			// done adding markers, hide overlay
			
			$(mapEl).siblings('.loading-overlay').addClass('hidden');

		};

		// init the map

		const { getMap, getInfoWindow, addMarker, geocodeLocation } = Maps.init(m => {
			m.addListener( 'idle', onBoundsChanged.bind( m ));
			addLocations();
		}, mapEl);

		// filter map markers by province

		const filterMarkers = ( province_id ) => {
			let bounds = new google.maps.LatLngBounds();

			// hide all markers
			Locations.forEach( loc => {
				if ( loc.marker ) {
					loc.marker.setMap( null );
				}
			} );

			// show markers for selected province only
			Locations
				.filter( loc => loc.province.id == province_id || province_id === all )
				.forEach( ( loc, i, a ) => {
					const markerLabel = ( i + 1 ).toString();

					if ( loc.marker ) {
						loc.marker.setLabel( markerLabel );
						loc.marker.setMap( getMap() ); // show
						bounds.extend( loc.marker.position );
					}
					
					getMap().fitBounds( bounds );
				} );			
		};

		// filter list items by map bounds

		const filterListItems = (province_id) => {	
			const bounds = getMap().getBounds();

			if ( !province_id ) {
				return;
			}

			// hide all list items

			Locations
				.forEach( loc => {
					if ( loc.listItem ) {
						loc.listItem.hide();
					}
				});			

			// show list items for map bounds only

			Locations
				.filter( loc => loc.province.id == province_id || province_id === all )
				.filter( loc => !loc.marker || ( loc.latLng && bounds.contains( loc.latLng ) ) )
				.forEach( ( loc, i, a ) => {

					if ( loc.marker ) {
						loc.listItem
							.show()
							.attr('data-id', loc.marker.label)
							.find('.number')
							.html(loc.marker.label);
					}
					else {
						loc.listItem
							.show();
					}
 
				});
		};

		// add event data to modal

		const populateModal = fair => {
			// show/hide relevant content
			$('[data-fair-virtual]').hide().filter(`[data-fair-virtual="${fair.is_virtual}"]`).show();
			$('[data-is-fair]').hide().filter(`[data-is-fair="${fair.is_fair}"]`).show();

			$('[data-fair-region-name]').html(fair.region_name || '');
			$('[data-fair-location]').html(fair.location || '');
			$('[data-fair-address]').html(fair.address || '');
			$('[data-fair-city]').html(fair.city || '');
			$('[data-fair-province]').html(fair.province.code || '');
			$('[data-fair-postal-code]').html(fair.postal_code || '');
			$('[data-fair-link]').attr('href', fair.event_id || '#');
			if ( fair.event_start_date && fair.event_end_date ) {
				$('[data-fair-dates]').html(dateRange(fair.event_start_date, fair.event_end_date));
			} 
			const encodedAddress = encodeURIComponent((fair.address || '') + ' '  + (fair.city || '') + ', ' + (fair.province.code || '') + ' ' + (fair.postal_code || '') );
			const img = `https://maps.googleapis.com/maps/api/staticmap?key=${env.googleMapsApiKey}&center=${encodedAddress}&zoom=12&size=1000x500&style=feature:poi|visibility:off`;
			$('[data-fair-map-image]').css('background-image', `url(${img})`);
		};

		// "this" is a google.maps.Marker

		function onMarkerClicked ( e ) {
			const infoWindow = getInfoWindow();
			const listItem = eventList.find(`[data-id=${this.label}]:visible`);
			const location = listItem.data('location');
			const position = location.latLng;
			const content = `${location.region_name}`;

			// display info window on marker
			if ( position && content ) {
				infoWindow.setPosition( position );
				infoWindow.setContent( content );
				infoWindow.setOptions( { pixelOffset: new google.maps.Size( 0, -30 ) } );
				infoWindow.open( getMap() );	
			}

			listItem.find('[data-modal]').trigger('click');	
		}

		// "this" is a google.maps.Map

		function onBoundsChanged ( e ) {
			if ( mapTimeout ) {
				clearTimeout(mapTimeout);
			}
			mapTimeout = setTimeout(() => {
				filterListItems(dropdown.val());
			}, 250);
		}

		const onDropdownChanged = e => {
			filterMarkers( $(e.currentTarget).val() );
		};
	},
	finalize () {
		
	},
};
